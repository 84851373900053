import React from "react";
import Title from "../layouts/Title";
import {
  projectOne,
  projectTwo,
  projectThree,
  projectFour,
  projectFive,
  projectSix,
  projectSeven,
  projectEight,
} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Loan Management"
          des=" Loan management is a crucial process in the financial industry that 
          involves the systematic handling of loans throughout their lifecycle. 
          This process encompasses a range of activities from loan origination, 
          processing, and approval, to disbursement, servicing, and repayment. 
          Effective loan management ensures that lenders can efficiently track 
          and manage their loan portfolios, mitigate risks, and provide a seamless 
          experience for borrowers."
          src={projectOne}
        />
        <ProjectsCard
          title="Personal Financial Manager"
          des=" A Personal Financial Manager (PFM) is a comprehensive tool designed to help 
          individuals take control of their financial lives. It provides a centralized platform
           for managing personal finances, enabling users to track their income, expenses, savings,
            and investments. The goal of a PFM is to empower individuals to make informed financial decisions, 
            achieve their financial goals, and secure their financial future."
          src={projectTwo}
        />
        <ProjectsCard
          title="AirBnB"
          des="Airbnb is a popular online marketplace that connects people looking for accommodations with those who have properties 
          available for short-term rental. Founded in 2008, Airbnb has revolutionized the travel and hospitality industry by offering
           a diverse range of lodging options, from private homes and apartments to unique stays like treehouses and castles."
          src={projectThree}
        />
        <ProjectsCard
          title="VibraLocal E-commerce"
          des="VibraLocal is an innovative e-commerce platform aimed at empowering local businesses by providing them with a user-friendly 
          online storefront to reach a wider audience. The project focuses on promoting community-driven commerce, offering features like 
          personalized recommendations, secure payment integration, and seamless order tracking."
          src={projectFour}
        />
        <ProjectsCard
          title="VibraLocal E-commerce - Frontend"
          des="The frontend of VibraLocal is crafted to deliver an engaging and intuitive user experience, designed with modern technologies 
           like React and Next.js. It features a responsive design optimized for all devices, ensuring seamless navigation for users. The project
           highlights include dynamic product pages, real-time search functionality, and visually appealing layouts tailored to enhance user
             interaction while maintaining a focus on accessibility and performance."
          src={projectFive}
          liveLink="https://www.vibralocal.live/"
        />
        <ProjectsCard
          title="AI ChatBot for Business Insights"
          des="An intelligent chatbot that answers business-related queries, retrieves real-time sales data, and provides insights using the Gemini API 
        and PostgreSQL. It dynamically processes user queries to generate SQL commands for accurate responses.With its ability to understand natural language, 
        the chatbot allows business owners, managers."
          src={projectSix} // Replace with your chatbot image
          liveLink="https://chatbot-ai-lovat.vercel.app/"
        />
        <ProjectsCard
          title="CRUD Employee Management App for Deboik"
          des="A full-stack CRUD application for employee management, built using React, Node.js, Express, and MongoDB. This project ensures a smooth and efficient user experience while implementing secure edit and delete actions. The confirmation password for security is '1234'."
          src={projectSeven} // Replace with your project screenshot
          githubLink="https://github.com/Motekema/employees-crud"
          liveLink="https://employees-crud-git-master-motekemas-projects.vercel.app/"
        />
        <ProjectsCard
          title="Holy Spirit AI | Church Chatbot"
          des="An AI-powered chatbot designed to provide Bible verses, motivational quotes, and spiritual guidance. This project blends technology and faith to create an engaging and uplifting experience for users."
          src={projectEight} // Replace with your project screenshot
          liveLink="https://verse-chat.vercel.app/"
        />
      </div>
    </section>
  );
};

export default Projects;
